@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-xs;
}

.highlight {
  border: $border-red;
  border-radius: $border-radius-md;
  padding: $spacing-xs;
  margin-bottom: $spacing-sm;
}

.icon {
  color: $text-secondary;
  opacity: 0.25;
}

.rightContainer,
.leftContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.deleteIcon {
  color: $red;
  display: block;
  margin-left: auto;
}

.expandIcon {
  color: $text-secondary;
}

.expandIcon:hover,
.deleteIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.actionList {
  margin-left: $spacing-sm;
  margin-bottom: $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
