@import '../../styles/variables.scss';

.comment {
  margin-bottom: $spacing-sm;
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;
  gap: $spacing-md;
  flex-wrap: wrap;
  align-items: center;
  background-color: $white;
  padding: $spacing-xs;
  justify-content: flex-end;
}
.icon {
  margin-left: auto;
}
.smallButton {
  max-width: 1rem;
}
.currentUser {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  align-items: flex-start;
  background-color: $white;
  padding: $spacing-xs;
}
.spacer {
  height: $spacing-sm;
}
.bottom {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: $spacing-md;
  padding: $spacing-sm 0;
  align-items: center;
}
.alignRight {
  margin-left: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.taskContainer {
  background-color: $white;
  padding: $spacing-sm;
}
