@import '../../styles/variables.scss';
.input {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  background-color: $white;
}
.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.transparent {
  background-color: transparent;
}
.avatar {
  height: $spacing-lg;
  width: $spacing-lg;
  border-radius: 50%;
}
.text {
  margin-left: $spacing-md;
  margin-top: $spacing-xs;
  word-wrap: break-word;
}
.comments {
  margin: $spacing-sm;
}
.card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  margin-bottom: $spacing-xs;
}

@media only screen and (max-width: $mobile-threshold) {
  .avatar {
    height: $spacing-md;
    width: $spacing-md;
  }
  .comments {
    margin: 0;
  }
  .input {
    padding: $spacing-xs;
  }
}
