@import '../../styles/variables.scss';
.split {
  display: flex;
  justify-content: space-between;
  gap: $spacing-sm;
}
.container {
  background-color: $white;
  padding: $spacing-md;
  min-height: calc(100vh - $navbar-height);
  width: calc(70%);
}
.marginLeft {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: $spacing-xs 0;
}
.actionItemCard {
  min-width: 100%;
  border: $border-ultra-light;
}
.contactCard {
  background-color: $white;
  border-radius: $border-radius-md;
  padding: $spacing-sm $spacing-md;
  width: fit-content;
  margin: 0 auto;
}

.selectors {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.badge {
  border-radius: $border-radius-sm;
  margin: $spacing-sm auto;
  width: 20rem;
  padding: $spacing-sm;
}
.footerContainer {
  background-color: white;
  padding: $spacing-md;
  box-shadow: $box-shadow;
  border-radius: $border-radius-lg;
}
.flexRight {
  @extend .flex;
  margin-left: auto;
  width: 30%;
}

.titleContainer {
  background-color: white;
  padding: $spacing-md;
  box-shadow: $box-shadow;
  border-radius: $border-radius-lg;
  display: flex;
  justify-content: space-between;
  gap: $spacing-md;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $spacing-sm;
}
.labelContainer {
  overflow-x: hidden;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: $spacing-xxs;
}
.submitButton {
  margin-left: auto;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: $spacing-sm;
  gap: $spacing-sm;
}
.right {
  max-width: 30%;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: $spacing-sm;
}

.signature {
  max-width: 20rem;
}
.flex {
  display: flex;
  align-items: center;
  gap: $spacing-md;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.flexSmall {
  @extend .flex;
  gap: $spacing-sm;
}
.number {
  max-width: 5rem;
}
.footer {
  display: flex;
  gap: $spacing-md;
  justify-content: space-between;
  align-items: center;
}

.referenceImage {
  padding: $spacing-md;
  background-color: white;
  border: $border;
  border-radius: $border-radius-md;
  display: block;
  margin: auto;
}
.sectionLine {
  background-color: $gray-ultra-light;
  height: 0.02rem;
  margin: $spacing-sm 0;
}
.paddingSm {
  padding: $spacing-sm;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: $spacing-sm;
}
.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  justify-content: space-between;
}

.check {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.emptyStateView {
  display: flex;
  justify-content: center;
}
.inspectionLink:hover {
  cursor: pointer;
}
.inspectionLink {
  display: flex;
  justify-content: space-between;
}
.outerImagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
}
.comment {
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;
  gap: $spacing-md;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
  padding: $spacing-xs;
  justify-content: flex-end;
}
.spacer {
  height: $spacing-sm;
}
.checkContainer {
  margin: $spacing-sm auto;
  background-color: white;
  padding: $spacing-md $spacing-lg;
  border-radius: $border-radius-lg;
  max-width: fit-content;
}
.actionItemsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}

.tableContainer {
  max-height: calc(100vh - 22rem);
  overflow-y: scroll;
}
.spinnerContainer {
  height: calc(100vh - 30rem);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red {
  color: $red;
}
.spinner {
  font-size: 1rem;
  height: 5rem;
  width: 5rem;
}
.aviation {
  max-width: 100%;
}

.statusContainer {
  display: flex;
  gap: $spacing-sm;
}
.emptyImage {
  display: flex;
  width: 20vw;
}
.newButton {
  display: flex;
  align-items: center;
  margin-bottom: auto;
}
.top {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: center;
}

.splitContainer {
  display: flex;
  justify-content: space-between;
}

.hideDesktop {
  display: none;
}
.imageContainer {
  height: 10rem;
  width: auto;
  background-color: $gray-translucent;
  padding: $spacing-xs;
}
.cardImage {
  border-radius: $border-radius-lg;
  height: 9rem;
  padding: $spacing-xxs;
  object-fit: cover;
  width: 10rem;
}
.cardDescription {
  margin: $spacing-sm;
  text-wrap: wrap;
  word-break: break-all;
  margin-top: 0;
  height: 5rem;
  overflow-y: scroll;
}
.imageContainer:hover {
  cursor: pointer;
}
.descriptionHeader {
  margin: $spacing-sm;
  margin-bottom: 0;
}
.deleteIcon:hover {
  cursor: pointer;
}

Table:hover {
  cursor: pointer;
}

.label {
  margin: auto;
}
.titleIcon {
  display: flex;
  gap: $spacing-xxs;
  align-items: center;
}
td {
  overflow-x: hidden;
}
.image {
  height: 8.5rem;
  border-radius: $border-radius-lg;
  width: auto;
}
.imagesContainer {
  height: 100%;
}
.card {
  height: 18rem;
  padding: $spacing-xs;
  max-width: 12rem;
}
.hide {
  visibility: hidden;
}
.bottomIcons {
  cursor: pointer;
  display: flex;
  gap: $spacing-xs;
  justify-content: flex-end;
  align-items: center;
}
.private {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: $spacing-sm;
    width: 100%;
  }

  .topContainer {
    padding: $spacing-sm;
  }
  .bottomContainer {
    gap: $spacing-md;
  }

  .hide {
    display: none;
  }
  .hideDesktop {
    display: block;
    opacity: 0.5;
  }

  .hideDesktop:hover {
    opacity: 0.25;
    cursor: pointer;
  }
  .hideOnMobile {
    display: none;
  }
}
