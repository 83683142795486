@import '../../../styles/variables.scss';

.modalGraphic {
  width: 25vh;
  min-width: 10rem;
  max-width: 70vw;
  display: block;
  margin: $spacing-sm auto;
}
.modalContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12.5%;
}
@media only screen and (max-width: $mobile-threshold) {
  .modalContentContainer {
    padding: $spacing-md;
  }
}
