@import '../../../styles/variables.scss';

.flex {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.info {
  display: flex;
  align-items: flex-start;
  background-color: white;
  justify-content: space-between;
  padding: $spacing-md;
  gap: $spacing-sm;
  border: $border-ultra-light;
  flex-wrap: wrap;
}
.list {
  max-width: 15rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.stats {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.input {
  max-width: 15rem;
}
.span {
  font-weight: $bold;
  font-size: $font-size-lg;
  color: black;
}
.label {
  font-size: $font-size-sm;
  padding: $spacing-xxs;
  border-radius: $border-radius-lg;
  background-color: $gray-dark;
  color: white;
}
.statBox {
  border-radius: $border-radius-lg;
  padding: $spacing-sm;
  background-color: white;
  min-width: 13rem;
  width: 13rem;
  border: $border-ultra-light;
}
.container {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.spacer {
  height: $spacing-md;
}
.spacerSm {
  height: $spacing-sm;
}
.statBoxLg {
  @extend .statBox;
  margin: 0 auto;
  width: 23rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-sm;
}
.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-bottom: $spacing-xs;
}
@media only screen and (max-width: $mobile-threshold) {
  .list {
    max-width: 100%;
    min-width: 15rem;
  }
  .container {
    justify-content: center;
  }
}
