@import '../../../styles/variables.scss';

.date {
  padding: $spacing-xs;
  border: $border-light;
  border-radius: $border-radius-md;
  width: 48%;
}
.dateSelectors {
  display: flex;
  justify-content: space-between;
  gap: $spacing-xs;
  width: 18rem;
}
.monthSelector {
  display: flex;
  gap: $spacing-sm;
}
.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.highlight {
  @extend .date;
  border-color: $red;
}
