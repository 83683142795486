@import '../../styles/variables.scss';
.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.page {
  padding-top: $spacing-sm;
}
.header {
  @extend flex-row-space-between;
}
.right,
.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.commentContainer {
  background-color: $white;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
}
.content {
  padding: 0 $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.spacer {
  height: $spacing-sm;
}
.left {
  width: 72%;
}
.rootCause {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.row {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.red {
  color: $red;
  font-size: large;
}

.buttons {
  display: flex;
  gap: $spacing-md;
  align-items: center;
  margin-top: auto;
}
.right {
  width: 25%;
}
.noHistory {
  height: $spacing-sm;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commentList {
  max-height: 20rem;
  overflow-y: scroll;
  padding: $spacing-xs;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
}
.empty {
  border-color: red;
}
.attachmentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}
.bottomLeft {
  width: 93%;
}
.footer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  justify-content: space-between;
  margin-top: $spacing-lg;
}

@media screen and (max-width: $mobile-threshold) {
  .container {
    flex-direction: column;
  }
  .left,
  .right {
    width: 100%;
  }
  .right {
    padding: $spacing-md $spacing-sm;
  }
  .modal {
    width: 100%;
  }
  .footer {
    margin-top: $spacing-md;
  }
}
