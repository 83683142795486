@import '../../styles/variables.scss';
.container {
  margin-bottom: $spacing-sm;
}
.noMargin {
  margin-bottom: 0;
}
.disabled {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  align-items: center;
  padding: 0.5rem;
  border-radius: $border-radius-md;
  position: relative;
  border: $border;
}
.container:hover {
  cursor: pointer;
}
.title {
  margin-bottom: $spacing-sm;
}
.iconOptions {
  display: flex;
  padding: $spacing-xs;
  justify-content: center;
}
.icons {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow;
  max-height: 15rem;
  overflow-y: scroll;
}

.selector {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  align-items: center;
  padding: $spacing-xs;
  border-radius: 8px;
  background-color: white;
  position: relative;
  border: $border;
}
.dropdown {
  @extend .disabled;
  opacity: 1;
}
.selected {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.highlight {
  @extend .selector;
  border: 1px solid red;
}
.dropdownContainer {
  position: relative;
  width: 100%;
}
.options {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow;
  max-height: 15rem;
  overflow-y: scroll;
}
.vehicleCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.vehicleCardContainer:hover {
  cursor: pointer;
  background-color: white;
}

.textContainer {
  width: 100%;
}

.vehicleContainer {
  max-height: 14rem;
  height: 13rem;
  width: 100%;
  overflow-y: scroll;
}

.vehicleImage {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  object-fit: cover;
  font-size: 2.5rem;
  display: block;
  margin: auto;
  border-radius: $border-radius-sm;
  margin-right: $spacing-xxs;
}

.option {
  padding: $spacing-xs;
  border-radius: 8px;
  width: 100%;
  align-self: center;
}
.option:hover {
  cursor: pointer;
}

.searchbar {
  margin-bottom: 0.5rem;
}

.typeSelector {
  display: flex;
  margin-left: 0;
  gap: $spacing-sm;
}
.red {
  color: $red;
}

.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
span {
  font-size: inherit;
  font-family: 'eina03semibold';
}
.no-scroll {
  position: fixed;
  overflow: hidden;
}

.selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: $mobile-threshold) {
  .options {
    width: 100%;
    padding-left: $spacing-xs;
    padding-right: $spacing-xs;
  }
  .option {
    font-size: $font-size-md;
  }
}
