$font-size-xxxs: 9px;
$font-size-xxs: 10px;
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 15px;
$font-size-lg: 22px;
$font-size-xl: 36px;

$text-primary: #1d1d1f; // Apple Primary
$text-secondary: #6e6e73; // Apple secondary
$text-secondary-light: #ced4da; // #868686; // FIGMA=C4C4C4

$mobile-threshold: 1000px;

$accent-primary-dark: #0a58ca;
$accent-primary: #0d6efd;
$accent-primary-translucent: #0d6efd77;
$accent-primary-translucent-background: #0d6efd10;
$accent-primary-highlight: #3d8bfd;
$accent-primary-ultralight: #6ea8fe;
$accent-primary-ultradark: #052c65;
$border-radius: 4px;

$white: #fafafd;
$black: #0b1833;
$black-translucent: #0b183355;

$yellow-dark: #c39b23;
$yellow: #ffc107;
$yellow-light: #f1c84b;

$green: #28a745;
$green-light: #479f76;
$green-dark: #198754;

$pink: #d63384;
$teal: #17a2b8;

// $red: #dc3545;
$red: #e9222c;
$red-light: #e35d6a;

$cyan: #0dcaf0;
$red-dark: #b02a37;
$purple: #6610f2;
$purple-light: #8c68cd;
$blue: #0d6efd;
$orange: #fd7e14;
$orange-dark: #fd7e14;
$orange-ultra-dark: #ca6510;

$text-primary-light: #fffc;

$border-color: #e8e8e8;

$inspection-sidebar-width: 20rem;

$border: 1px solid #0003;
$border-light: 0.1px solid $text-secondary;
$border-highlight: 1px solid $accent-primary-ultralight;
$border-red: 0.1px solid $red;
$border-ultra-light: 0.1px solid $text-secondary-light;

$sidebar-width: 13rem;

$navbar-height: 4rem;

$gray-translucent: #abb5be33;
$gray-light: #abb5be;
$gray: #6c757d;
$gray-dark: #54595e;
$gray-dark-translucent: #54595e55;
$gray-ultra-light: #e9ecef;

$dark-theme-dark-gray: #3a3a3a;
$dark-theme-medium-gray: #2f2f2f;
$dark-theme-light-gray: #4c4c4c;

$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 12px;

$box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

$spacing-xxs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-sm: 0.75rem;
$spacing-md: 1.5rem;
$spacing-lg: 3rem;
$spacing-xl: 4.5rem;
$spacing-xxl: 6rem;
$red-highlight-border: 1px solid rgba(227, 59, 59, 0.5);
$blue-divider: 1px solid rgba(13, 110, 253, 0.5);

$semi-bold: 'eina03semibold';
$bold: 'eina03bold';

.disabled {
  background-color: $gray-translucent;
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.disabled:hover {
  background-color: $gray-translucent;
  cursor: default;
  opacity: 0.5;
}
flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
flex-column-space-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

:export {
  mobileThreshold: $mobile-threshold;
  flex-row-space-between: flex-row-space-between;
  flex-column-space-between: flex-column-space-between;
}
