@import '../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-sm;
  flex-direction: column;
}

.inputContainer {
  position: relative;
}

.formInput {
  width: 100%;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border-radius: $border-radius-lg;
  max-height: 10rem;
  overflow-y: auto;
  z-index: 1000;
  margin-top: $spacing-xs;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
  padding: $spacing-sm;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  &:hover {
    background-color: $white;
  }
  &:last-child {
    border-bottom: none;
  }
}
