@import '../../../styles/variables.scss';

.card {
  border-radius: $border-radius-lg;
  width: 13rem;
  background-color: white;
  padding: $spacing-sm;
  border: $border-ultra-light;
  min-height: 9rem;
}
.header {
  @extend flex-row-space-between;
  align-items: flex-start;
  margin-bottom: $spacing-xs;
}
.units {
  font-size: $font-size-sm;
}
.middle {
  @extend .header;
  align-items: flex-end;
}
.icon {
  transform: translateY(-0.5rem);
}
.bottom {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.footer {
  @extend .bottom;
  border-top: $border;
  padding: $spacing-xs;
}
