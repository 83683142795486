@import '../../../styles/variables.scss';

.image {
  object-fit: contain;
  border-radius: $border-radius-lg;
  background-color: white;
  cursor: pointer;
}
.sm {
  max-height: 6rem;
  min-height: 5rem;
  max-width: 6rem;
  min-width: 6rem;
}
.md {
  max-height: 8rem;
  min-height: 7rem;
  max-width: 8rem;
  min-width: 8rem;
}
.lg {
  margin: 0 auto;
  max-height: 12rem;
  min-height: 8rem;
  max-width: 12rem;
  min-width: 12rem;
}
.container {
  padding: $spacing-sm;
  display: flex;
  align-items: center;
  gap: $spacing-md;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.row {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
}
.details {
  display: flex;
  gap: $spacing-lg;
  align-items: center;
  width: 100%;
}
.highlight {
  border: 1px solid red;
  max-width: 10rem;
}
