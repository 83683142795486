@import '../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  gap: $spacing-sm;
  width: 100vw;
}

.logo {
  width: 10vh;
  position: absolute;
  left: calc(50vw - 5vh);
  top: calc(50vh - 5vh);
}

.spinner {
  position: absolute;
  left: calc(50vw - 6vh);
  top: calc(50vh - 7vh);
  color: $accent-primary;
  width: 12vh;
  height: 12vh;
  margin-bottom: 20px;
  min-width: 12vh;
  min-height: 12vh;
  z-index: 1;
  color: $accent-primary;
}

.text {
  position: absolute;
  top: calc(57vh);
  text-align: center;
}
