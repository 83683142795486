@import '../../styles/variables.scss';
@import '../../components/Button.module.scss';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: $font-size-lg;
  margin-bottom: 3rem;
}

.label {
  margin-bottom: $spacing-xxs;
}

.avatar {
  width: 10rem;
  border-radius: 50%;
  height: 10rem;
  object-fit: contain;
}

.avatarContainer {
  position: relative;
  width: 10rem;
  display: block;
  margin: auto;
}

.avatarButtonContainer {
  position: absolute;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: $accent-primary;
  justify-content: center;
  bottom: 0;
  right: 0;
}

.avatarButtonContainer:hover {
  background-color: $accent-primary-highlight;
  cursor: pointer;
}

.editButton {
  height: 1rem;
  align-self: center;
}

.subtitle {
  font-size: $font-size-lg;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.infoContainer {
  width: 100%;
  max-width: 30rem;
  margin: auto;
  margin-bottom: $spacing-xxl;
}
.supervisorSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonDisplay {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  background-color: white;
  bottom: 0;
  right: 0;
  padding: $spacing-sm $spacing-md;
  width: 100%;
}

@media only screen and (max-width: $mobile-threshold) {
  .saveButton {
    width: 100%;
  }
}

.hide {
  display: none;
}
