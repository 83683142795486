@import '../styles/variables.scss';

.table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  th,
  td {
    padding: $spacing-xxs;
    text-align: center;
    border: none;
    align-items: center;
  }
}
.container {
  width: 100%;
  padding: $spacing-md $spacing-sm;
  border-radius: $border-radius-lg;
  border: $border-ultra-light;
}
.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-sm;
}
.center {
  border: $border;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  width: 12rem;
  margin: 0 auto;
}

.highlight {
  @extend .cell;
  font-weight: bolder;
  border: $border-highlight;
  background-color: $gray-ultra-light;
}
.headerHighlight {
  @extend .header;
  font-weight: bolder;
  border: $border-highlight;
  background-color: $gray-ultra-light;
}
.cell {
  display: flex;
  height: 2rem;
  min-width: 2rem;
  border-radius: $border-radius-sm;
  align-items: center;
  justify-content: center;
  color: white;
}
.header {
  @extend .cell;
  color: black;
}
.tableDisplay {
  display: flex;
  align-items: center;
  padding-right: $spacing-md;
}
.rotate {
  transform: rotate(-90deg) translateX(-4rem);
}
.rotateContainer {
  vertical-align: auto;
  width: 1.3rem;
  height: 100%;
}
.hideOnDesktop {
  display: none;
}

@media only screen and (max-width: 600px) {
  .hideOnMobile {
    display: none;
  }
  .hideOnDesktop {
    display: block;
  }
  .container {
    padding: $spacing-xxs;
  }
}
