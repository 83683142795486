@import '../../styles/variables.scss';
.extensions {
  background-color: $white;
  padding: $spacing-sm;
  border-radius: $border-radius-md;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.modal {
  width: 45vw;
}
.check {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.modalContent {
  padding: 0 $spacing-md;
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iconText {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.row {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.flex {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dateDisplay {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: $spacing-md;
  align-items: center;
}
.date {
  padding: $spacing-xs;
  border-radius: $border-radius-md;
  border: $border-light;
}
