@import '../../styles/variables.scss';

.incidentEmergencyMessageContainer {
  width: 100%;
  height: 100%;
}

.workspaceTableContainer {
  position: relative;
  z-index: 0;
}
.locationContainer {
  background-color: white;
  padding: $spacing-md;
  width: 100%;
  border-radius: $border-radius-lg;
}

.flexRow {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.location {
  display: flex;
  gap: $spacing-sm;
}
.spacer {
  height: $spacing-sm;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  max-width: 40%;
  min-width: 40%;
  align-self: flex-start;
}
.header {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  max-width: 100%;
}

.logo {
  border-radius: 50%;
  height: $spacing-lg;
  width: $spacing-lg;
}
.container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: $spacing-lg;
  align-items: center;
  background-color: $white;
  cursor: auto;
  width: 80vw;
  background-color: $white;
  padding-bottom: $spacing-lg;
}
.detailPanel {
  background-color: $white;
  padding: $spacing-md;
}

.worspaceQuickActionsContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}
