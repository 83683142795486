@import '../../../styles/variables.scss';

.fileList {
  max-height: 20rem;
  overflow-y: scroll;
  margin-bottom: $spacing-md;
}

.fileName {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: $spacing-sm;
}

.green {
  color: $green;
}
