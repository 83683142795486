@import '../../../styles/variables.scss';
.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  background-color: $white;
  margin: $spacing-md;
  border-radius: $border-radius-lg;
  overflow: visible;
}
.chartContainer {
  height: 45vh;
  width: 45vw;
  overflow: visible;
}
.hide {
  width: 0;
  visibility: hidden;
  display: none;
}
.legend {
  margin-top: auto;
}
.legendText {
  display: flex;
  gap: $spacing-sm;
}
.page {
  background-color: white;
  height: 50vh;
  width: 60vw;
  padding: $spacing-md;
  border-radius: $border-radius-lg;
  display: flex;
  justify-content: space-between;
  background-color: white;
  overflow: visible;
  border: $border-ultra-light;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: visible;
  width: 100%;
  gap: $spacing-md;
}

.content > *:only-child {
  flex: 1;
}

.right {
  width: 35vw;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-right: $spacing-sm;
}
.text {
  font-size: 18px;
}
.flex {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.tab {
  font-family: 'eina03semiBold';
  font-size: $font-size-md;
  align-self: flex-end;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emptyStateContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-lg;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-bottom: $spacing-xs;
}
.image {
  height: 10rem;
}
.button {
  margin-top: $spacing-sm;
}
.alignRight {
  margin-left: auto;
}
.filters {
  display: flex;
  gap: $spacing-xs;
  flex-direction: column;
  overflow: visible;
  width: 15vw;
}

.marginLeft {
  margin-left: auto;
}
@media only screen and (max-width: $mobile-threshold) {
  .content {
    flex-direction: column;
    width: 90vw;
  }
  .filters,
  .right,
  .legend {
    width: 90vw;
  }
  .page {
    flex-direction: column;
    min-height: unset;
    width: 90vw;
    height: unset;
  }
  .chartContainer {
    width: 75vw;
    margin: auto;
  }
}
