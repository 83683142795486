@import '../../../styles/variables.scss';

.menu {
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: $spacing-sm;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-md;
}

.divider {
  border-top: solid $text-secondary-light 0.02rem;
  height: $spacing-sm;
}

.verticalDivider {
  border-left: solid $text-secondary-light 0.02rem;
}

.actionList {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  height: 35vh;
  width: 100%;
}

.popupContainer,
.popupSubContainer,
.messageContainer,
.messageSubContainer,
.notifySubContainer,
.notifyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popupContainer,
.messageContainer,
.notifyContainer {
  justify-content: space-between;
}
.popupSubContainer,
.messageSubContainer,
.notifySubContainer {
  gap: $spacing-sm;
}

.popupIcon,
.messageIcon,
.notifyIcon {
  color: $text-secondary;
  opacity: 0.25;
}

.notifyForm {
  padding: $spacing-xs;
}

.deleteIcon {
  color: $red;
}

.deleteIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.highlight {
  border: 1px solid red;
}

.popUpInput {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.popupForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.popupCloseIcon {
  align-self: flex-start;
}

.truncate {
  width: 20vw;
}

.monthDayContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  width: 100%;
}

.selector {
  width: 100%;
}

.monthPickerContainer {
  margin-bottom: $spacing-sm;
}

.checkbox {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
}
