@import '../../../styles/variables.scss';
$modal-width: 32rem;

.container {
  background-color: $white;
  z-index: 6000;
  min-width: 25vw;
}

.contentContainer {
  padding: 2rem;
  padding-bottom: 1rem;
}
.middleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  font-size: 3.5rem;
}

.dangerIcon {
  color: $red;
}

.warningIcon {
  color: $yellow;
}

.successIcon {
  color: $green;
}
.purpleIcon {
  color: $purple;
}

.textContainer {
  margin-left: 2rem;
  max-width: 22rem;
  word-wrap: break-word;
}
.description {
  word-wrap: break-word;
}

.confirmTextContainer {
  margin-top: 2rem;
  width: 100%;
}

.textInput {
  width: 100%;
}

.topbar {
  display: block;
  height: 0.5rem;
  width: 100%;
  border-top-left-radius: $border-radius-sm;
  border-top-right-radius: $border-radius-sm;
}

.dangerTopbar {
  background-color: $red;
}

.warningTopbar {
  background-color: $yellow;
}

.successTopbar {
  background-color: $green;
}
.purpleTopbar {
  background-color: $purple;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 75vw;
  }

  .middleContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: $spacing-sm;
  }
  .contentContainer {
    padding: 1rem;
    padding-bottom: 1rem;
  }
  .textContainer {
    margin-left: $spacing-sm;
  }
  .buttonContainer {
    justify-content: center;
  }
}
