@import '../../styles/variables.scss';

.container {
  width: $inspection-sidebar-width;
  padding: $spacing-md $spacing-sm;
  margin-right: $spacing-xs;
  background-color: $white;
  padding-bottom: $spacing-lg;
  min-width: 18rem;
  overflow-y: scroll;
  position: fixed;
  max-height: calc(100vh - $navbar-height - $spacing-lg);
  top: $navbar-height;
  right: 0;
}
.closeIcon {
  display: none;
}

.deleteButton {
  width: 100%;
  height: auto;
}
.pdfButton {
  width: 100%;
  margin-bottom: $spacing-sm;
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.editable {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.userContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.approveIcon {
  display: block;
  color: $green;
}

.changeRequestIcon {
  display: block;
  color: $red;
}

.addIcon {
  color: $accent-primary;
}

.addIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}
.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}
.icon {
  color: $gray-light;
}

.icon:hover {
  opacity: 0.25;
  cursor: pointer;
}

.closeIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
}

.labelContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-xs;
}
.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}

.heading {
  margin-bottom: $spacing-xxs;
  font-size: $font-size-sm;
}

.participantContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.archive {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.archiveIcon {
  cursor: pointer;
}
.spacer {
  height: $spacing-sm;
}
.actionItemList {
  max-height: 10rem;
  overflow-y: scroll;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100vw;
    padding-left: $spacing-lg;
    padding-right: $spacing-lg;
    position: static;
  }

  .closeIcon {
    display: block;
  }
}

.actionItemList:hover,
.vehicleLink:hover,
.actionItems:hover {
  cursor: pointer;
}
.actionItemList {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.previousStateList {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  max-height: 12rem;
  overflow-y: scroll;
  padding-bottom: $spacing-sm;
}

.cardContainer:hover {
  cursor: pointer;
  background-color: $white;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  background-color: white;
}

.downloadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadIcon {
  color: $accent-primary;
}
