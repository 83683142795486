@import '../../../styles/variables.scss';

.spacerSm {
  margin-top: $spacing-sm;
}

.spacerMd {
  margin-top: $spacing-md;
}

.deleteButton {
  width: 100%;
}

.taskImage {
  height: 5rem;
  width: 5rem;
}

.modalMinWidth {
  min-width: 35rem;
  overflow: auto;
}

.flexBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flexBoxGap {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalMinWidth {
    min-width: unset;
  }
}
