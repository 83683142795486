@import '../../../styles/variables.scss';
.field {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.maxWidth {
  max-width: 40vw;
  min-width: 40rem;
}
.date {
  padding: $spacing-xs;
  border: $border-light;
  border-radius: $border-radius-md;
}
.image {
  border-radius: $border-radius-lg;
}
.file {
  padding: $spacing-sm;
  box-shadow: $box-shadow;
  border-radius: $border-radius-lg;
}
.images {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  max-width: 100%;
  overflow-x: scroll;
  align-items: center;
  padding-bottom: $spacing-xs;
}
.red {
  color: $red;
}
.spacer {
  height: $spacing-sm;
}
@media only screen and (max-width: $mobile-threshold) {
  .maxWidth {
    max-width: 95vw;
    min-width: 95vw;
  }
}
