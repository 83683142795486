@import '../../styles/variables.scss';

.row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.rowTitle {
  display: flex;
}
.formLabel {
  margin: $spacing-xxs 0;
}

.dateContainer {
  width: 100%;
}

.odometer {
  display: flex;
  gap: $spacing-xs;
}

.editVehicleForm {
  display: flex;
  flex-direction: column;
}
.editVehicleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  position: relative;
  background-color: $white;
  width: 50rem;
  min-width: 50rem;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
  min-height: 25rem;
  justify-content: center;
  overflow: scroll;
}
.show {
  animation: show 0.3s ease-in;
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
  align-items: center;
  justify-content: center;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-md;
}
.closeIcon:hover {
  cursor: pointer;
}

.button {
  width: 100%;
  align-items: right;
  justify-content: right;
}
.saveButton {
  margin-left: auto;
  margin-top: $spacing-md;
}

.formLabel {
  display: block;
  width: 20rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    max-width: 95vw;
    min-width: 95vw;
    padding-bottom: $spacing-lg;
  }
  .row {
    flex-direction: column;
    gap: $spacing-xs;
  }
}
