@import '../../styles/variables.scss';

.page {
  background-color: $white;
  min-height: calc(100vh - $navbar-height);
  padding: $spacing-md;
}

.addButton,
.deleteButton {
  cursor: pointer;
}
.addButton {
  color: $accent-primary;
}
.deleteButton {
  color: $red;
}

.split {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.container {
  background-color: $white;
  padding-right: $spacing-md;
}

.image {
  width: 50%;
  display: block;
  margin: auto;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
}
.top {
  padding: $spacing-md;
  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  margin: $spacing-md;
}

.categoryInput {
  display: flex;
  gap: $spacing-sm;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: $spacing-sm;
}

.questionTitle {
  margin-left: 1rem;
  font-size: $font-size-md;
}
.required {
  color: $red;
  font-weight: bold;
}
.text {
  word-break: break-all;
  text-wrap: wrap;
}

.categoryHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
}

.categoryHeaderOptionContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
.formInput {
  width: 100%;
}

.addIcon:hover {
  color: $accent-primary-highlight;
  cursor: pointer;
}

.icon {
  color: $text-secondary;
  opacity: 0.25;
}

.categoryWindow {
  background-color: white;
  padding: $spacing-md;
  border-radius: 8px;
  width: 49%;
  box-shadow: $box-shadow;
}

.categoryContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $spacing-sm;
}

.emptyStateContainer {
  margin: auto;
  margin-top: $spacing-md;
  margin-bottom: $spacing-sm;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noSplit {
  width: 100%;
}

.graphic {
  width: 10rem;
  margin-bottom: $spacing-lg;
}

.label {
  margin-bottom: $spacing-xs;
}
.bottom {
  padding: 0 $spacing-md;
}

.inputContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.categoryLabel {
  margin-bottom: $spacing-sm;
}

.deleteIcon {
  color: $red;
  display: block;
  margin-left: auto;
}

.deleteIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.button {
  margin-bottom: $spacing-md;
  margin-right: $spacing-md;
}

.addQuestionButton {
  padding: $spacing-xs;
  padding-right: $spacing-sm;
  margin-left: auto;
  margin-right: $spacing-sm;
}

.questionGraphic {
  display: block;
  width: 30%;
  margin: auto;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subHeader {
  display: flex;
  justify-content: space-between;
}

.moreIcon {
  display: block;
}

.contextMenu {
  position: relative;
  transform: translateY(-2rem);
  z-index: 5;
}
.questionIcon {
  font-size: $font-size-md;
}
.editIcon {
  opacity: 0.5;
  cursor: pointer;
}
.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}

.moreIcon:hover {
  color: $gray;
  cursor: pointer;
}
.icons {
  display: flex;
  gap: $spacing-xxs;
}

.highlight {
  @extend .categoryWindow;
  border: $border-red;
}

.graphic {
  display: block;
  width: 50%;
  margin: auto;
  opacity: 0.25;
  margin-bottom: $spacing-sm;
}
.selectedOptions {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
  font-size: $font-size-md;
  margin: $spacing-sm;
}
.selectedOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.imageContainer {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
}
.moreContainer {
  display: flex;
  gap: $spacing-sm;
}
.fileUpload {
  margin-top: $spacing-sm;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalContainer {
  margin: auto;
  max-width: 30vw;
}
.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.switchIconContainer {
  background-color: $white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: $box-shadow;
}

.bundleSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-xxs;
}

.draftRevisionLink:hover {
  cursor: pointer;
  color: $accent-primary-translucent;
}

.revisionContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.revision {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-md;
  margin-bottom: $spacing-sm;
}

.revision:hover {
  cursor: pointer;
  opacity: 0.7;
}

.optionsButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-md;
}

.questions {
  display: flex;
  gap: $spacing-sm;
  flex-direction: column;
}

.conditionalActionContainer {
  padding: $spacing-sm;
}

.conditionalActionContainer:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media only screen and (max-width: $mobile-threshold) {
  .categoryWindow {
    width: 100%;
  }

  .image {
    width: 100%;
  }

  .imageContainer {
    flex-direction: column;
  }

  .doc {
    flex-direction: column;
  }
}

.documentNumber {
  margin: $spacing-sm 0;
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
}

.formIdInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.formIdLabel {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
  flex-wrap: wrap;
}

.headerContainer {
  margin-bottom: $spacing-sm;
}

.startingAt {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
}

.formIdContainer {
  margin-bottom: $spacing-sm;
}
.spacer {
  height: $font-size-md;
}
.doc {
  display: flex;
  gap: $spacing-sm;
}
.date {
  margin-top: $spacing-xxs;
}
