@import '../../../styles/variables.scss';

.container {
  z-index: 1200;
}

.left {
  width: 100%;
  flex: 1;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  background-color: $dark-theme-medium-gray;
  overflow: scroll;
}
.modalIcon {
  font-size: $font-size-lg;
  color: $accent-primary;
}
.mainHeader {
  @extend .header;
  padding-bottom: $spacing-sm;
  border-bottom: 0.02rem solid $gray;
}
.flex {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $dark-theme-medium-gray;
  padding: $spacing-md;
  border-radius: $border-radius-lg;
}

.responseContainer {
  margin-bottom: $spacing-sm;
  border-radius: $border-radius-lg;
  background-color: white;
  padding: $spacing-sm;
  box-shadow: $box-shadow;
  position: relative;
  background-color: $dark-theme-light-gray;
}

.responseContainer:hover {
  cursor: pointer;
}
.responseList {
  overflow: scroll !important;
}

.responseQuery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $dark-theme-light-gray;
}

.sectionLine {
  border-top: 0.02rem solid $gray;
  margin: $spacing-xs 0;
}

.deleteIcon {
  color: red;
}

.badIcon {
  color: $text-secondary-light;
}

.selected {
  border: solid $accent-primary 1px;
}

.referenceLink:hover,
.badIcon:hover,
.deleteIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.spinner {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $accent-primary;
}

.imgIcon {
  color: $accent-primary;
  width: 100%;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: $spacing-sm;
}

.formContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-xs;
}
.spacer {
  height: $spacing-sm;
}

.button {
  margin-top: $spacing-sm;
}

.link {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
  margin: $spacing-xs 0;
}
.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinnerWrapper {
  position: relative;
  display: inline-block;
}

.spinner {
  position: relative;
  width: 6rem;
  height: 6rem;
}

.spinnerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.spinnerText {
  position: absolute;
  top: calc(50% + 4rem);
}

.cardFooter,
.cardIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardIcons {
  gap: $spacing-xs;
}
.full {
  @extend .left;
  min-height: 80vh;
}
.hide {
  display: none;
}
.searchBar {
  display: flex;
  width: 100%;
  gap: $spacing-sm;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-theme-light-gray;
  padding: $spacing-xs;
  margin-top: $spacing-sm;
  border-radius: $border-radius-lg;
}

.cardFooter {
  justify-content: space-between;
}
.divider {
  margin-bottom: $spacing-xs;
  border-bottom: $text-secondary-light solid 1px;
}
.vectorInput {
  display: flex;
  justify-content: space-between;
  gap: $spacing-md;
}
.modalContainer {
  position: fixed;
  margin: 0 auto;
  right: 0;
  top: $navbar-height;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  width: 40vw;
  padding: $spacing-md;
  background-color: $dark-theme-dark-gray;
  z-index: 1200;
  align-items: space-between;
  overflow-y: scroll;
}
.minimize {
  @extend .modalContainer;
  max-width: 3rem;
  gap: $spacing-sm;
  align-items: center;
  justify-content: flex-start;
  padding: $spacing-sm;
}
.spinnerSmall {
  max-width: 1.5rem;
  max-height: 1.5rem;
}
.header {
  @extend flex-row-space-between;
  width: 100%;
  align-items: center;
}
.whiteIcon {
  margin-left: auto;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalContainer {
    width: 100vw;
    height: 100vh;
  }
}
