@import '../../../styles/variables.scss';

.square {
  width: 10rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: $border-radius-md;
}

.mediaWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.mediaWrapper:hover .overlay {
  opacity: 1;
}

.mediaWrapper:hover .media {
  filter: blur(2px);
}

.fileBox {
  text-align: center;
  padding: 10px;
}
