@import '../../../styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.title {
  margin-bottom: $spacing-sm;
}
.container {
  padding: $spacing-md $spacing-xs;
  width: 35vw;
}
.red {
  color: $red;
  font-size: large;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100%;
  }
}
