@import '../../../styles/variables.scss';

.content {
  margin-left: $spacing-sm;
}

.divider {
  border-top: 1px solid $text-secondary-light;
  margin-bottom: $spacing-sm;
}

.userContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.container {
  margin-top: $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.textContainer {
  width: fit-content;
}
