@import '../../styles/variables.scss';

.card {
  display: flex;
  border-radius: $border-radius-lg;
  min-height: calc(100vh - $navbar-height);
  max-height: calc(100vh - $navbar-height);
}
.green {
  width: $spacing-md;
  background-color: $green-light;
}
.red {
  @extend .green;
  background-color: $red-light;
}
.imageContainer {
  background-color: white;
  border-radius: $border-radius-lg;
  padding: $spacing-sm;
  border: $border;
  max-width: 12rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.gray {
  @extend .green;
  background-color: $text-secondary;
}
.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleContainer {
  background-color: white;
  border-radius: $border-radius-lg;
  padding: $spacing-md;
  margin: $spacing-md 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  box-shadow: $box-shadow;
}
.spacer {
  height: $spacing-md;
}
.column {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.editIcon {
  margin-left: auto;
}
.flexBetween {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 0.5fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: $spacing-md;
}

.icons {
  display: flex;
  align-items: center;
}
.closed {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.textInput {
  margin-top: $spacing-sm;
  white-space: pre-wrap;
}
.left {
  padding: $spacing-md;
  width: 100%;
  border-radius: $border-radius-lg;
  overflow-y: scroll;
}
.incidentText {
  margin-bottom: $spacing-xs;
}
.private {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.right {
  padding: $spacing-md;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  max-height: 90vh;
  min-height: 90vh;
  min-width: $inspection-sidebar-width;
  max-width: $inspection-sidebar-width;
}

.images {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
}
.button {
  width: 100%;
  margin: $spacing-xs 0;
}

.image {
  height: 9rem;
  border-radius: $border-radius-lg;
  justify-self: center;
  width: 10.5rem;
  object-fit: fill;
}
.image:hover {
  cursor: pointer;
  opacity: 0.8;
}
.actionItemCard {
  width: 100%;
  box-shadow: none;
  border: $border-ultra-light;
}
.actionItem {
  padding: $spacing-sm;
  background-color: $white;
  margin: $spacing-sm 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: $border-radius-lg;
}

.team {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.icon {
  font-size: 20rem;
}
.text {
  margin-bottom: $spacing-sm;
}
.sectionLine {
  border-top: 0.02rem solid $gray-ultra-light;
  margin-top: $spacing-sm;
}

.prewrap {
  white-space: pre-wrap;
}

@media only screen and (max-width: $mobile-threshold) {
  .content {
    flex-direction: column;
    padding-bottom: $spacing-md;
    overflow-y: scroll;
  }
  .right {
    padding-left: $spacing-md;
  }
  .left,
  .right {
    min-width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: $white;
    overflow: auto;
    max-height: 90%;
    min-height: fit-content;
  }
}
