@import '../../styles/variables.scss';
.header {
  @extend flex-row-space-between;
  align-items: flex-start;
}
.background {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.topButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-sm;
}
.taskImage {
  height: 5rem;
  width: 5rem;
}
.check {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.red {
  color: $red;
  font-size: large;
}

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  overflow-y: scroll;
  width: 40vw;
  margin-bottom: $spacing-md;
  padding: 0 $spacing-sm;
}
.question {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.row {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.matrix {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.spinner {
  display: flex;
  justify-content: center;
}
.button {
  width: 48%;
  border-radius: $border-radius-lg;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100%;
  }
}
