@import '../../styles/variables.scss';

.roleBox {
  margin-top: $spacing-sm;
  background-color: $white;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  overflow-y: auto;
  max-height: 16rem;
}

.requirementBox {
  margin-top: $spacing-sm;
  background-color: white;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  overflow-y: visible;
}

.requirementBoxTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addIcon {
  color: $accent-primary;
}

.addIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.editIcon {
  margin-left: auto;
  color: $text-secondary;
  background-color: white;
}

.editIcon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.certificateContainer {
  @extend .requirementBox;
  background-color: white;
  overflow: auto;
  transition: max-height 0.3s ease, padding 0.3s ease;
  max-height: 0;
  min-height: 0;
}

.expanded {
  max-height: 200px;
  padding: $spacing-sm;
  min-height: 5rem;
}

.collapsed {
  max-height: 0;
  padding: 0 $spacing-sm;
}

.certificateCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-sm;
}

.spacerSm {
  margin-top: $spacing-sm;
}
