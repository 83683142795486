@import '../../../styles/variables.scss';

$quick-action-size: 5.5rem;
.wrapper {
  position: relative;
  display: inline-block;
  height: $quick-action-size;
  width: $quick-action-size;
  margin: $spacing-md auto;
  border-radius: $border-radius-lg;
}
.top {
  display: flex;
  gap: $spacing-sm;
}

.buttonPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-xxs;
  font-size: 24pt;
  width: $quick-action-size;
  height: $quick-action-size;
  border-radius: $border-radius-lg;
}
.buttonPreview:hover,
.colorPicker:hover {
  cursor: pointer;
}
.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: $spacing-sm;
}
.colorInput {
  width: 100%;
  visibility: hidden;
  height: 100%;
}
.input {
  display: flex;
  gap: $spacing-lg;
}

.urlInput {
  border: $border;
  border-radius: $border-radius-md;
  padding: $spacing-xs;
  background-color: white;
  font-size: $font-size-md;
  min-width: 100%;
}
.section {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.text {
  word-wrap: break-word;
  text-wrap: wrap;
  overflow: hidden;
  word-break: break-all;
}

.colorPicker {
  position: absolute;
  opacity: 0;
  margin-top: $spacing-md;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.containerLink {
  background-color: $white;
  width: 6.5rem;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $font-size-lg;
  padding: $spacing-sm;
  gap: $spacing-sm;
  border-radius: $border-radius-md;
}

.borderColor {
  border: 2px solid $blue;
}

.containerLink:hover {
  cursor: pointer;
  background-color: $white;
}

.icon {
  color: $accent-primary;
}

.quickActionSelectionContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
}

.dropdownsContainer {
  min-height: 40vh;
}
