@import '../../styles/variables.scss';

.slider {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-sm;
}
.spacer {
  height: $spacing-sm;
}
.warning {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  margin: $spacing-md;
}
.sliderContainer {
  padding-right: $spacing-sm;
}

.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.red {
  color: $red;
}

@media only screen and (max-width: $mobile-threshold) {
  .selector {
    margin-bottom: 0;
  }
}
