@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  max-width: 95vw;
  padding: $spacing-md;
  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  overflow-y: scroll;
  min-height: 40vh;
  max-height: 95vh;
}
.contentContainer {
  padding: $spacing-sm;
}

.titleContainer {
  display: flex;
  gap: $spacing-sm;
}
.leftTitle {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.background {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.header {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-sm;
  flex-wrap: wrap;
  margin-top: auto;
}

.closeIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.quickAction {
  transform: translateY(-1rem);
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 95vw;
    max-width: 95w;
    width: 96vw;
    padding: $spacing-md $spacing-sm;
    max-height: 85vh;
  }
  .header {
    padding: $spacing-sm;
  }
  .footer {
    padding: $spacing-sm;
  }

  .contentContainer {
    max-height: 100%;
  }
}
