@import '../../styles/variables.scss';
.container {
  padding-left: $spacing-md;
  padding-right: $spacing-md;
  margin-right: $spacing-md;
}
.page {
  padding: $spacing-md;
  margin-bottom: $spacing-md;
}
.templateDropdown {
  margin-top: $spacing-sm;
  width: 50%;
}
.assetModalContainer {
  position: fixed;
  height: 22.5rem;
  border: $border;
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10;
  width: 50vw;
  left: 25vw;
  right: 25vw;
  top: calc(50vh - 10rem);
}
.spacer {
  height: $spacing-sm;
}
.hide {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftHeader {
  width: 70%;
}
.input {
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;
  align-items: center;
}
.logoContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 8rem;
}

.spinnerContainer {
  width: 100vw;
  height: calc(100vh - $navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: $spacing-lg;
  height: $spacing-lg;
}
.plusIcon {
  color: $accent-primary;
}

.editIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}
.logo {
  height: 9rem;
  align-self: flex-end;
  border-radius: $border-radius-md;
}

.logoButtonContainer {
  position: absolute;
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: $border-radius-md;
  background-color: $accent-primary;
  justify-content: center;
  bottom: 0;
  right: 0;
}

.logoButtonContainer:hover {
  background-color: $accent-primary-highlight;
  cursor: pointer;
}

.logoEditButton {
  color: $white;
  align-self: center;
}

.input {
  display: flex;
  justify-content: space-between;
  gap: $spacing-sm;
}

.plusIcon:hover,
.logoEditButton:hover {
  cursor: pointer;
}

.formRow {
  display: flex;
  gap: $spacing-lg;
}
.formComponent {
  min-width: 14rem;
  margin-bottom: $spacing-sm;
}
.statusRadio {
  display: flex;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
}
.assetContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  border: $border;
  border-radius: $border-radius-md;
  background-color: white;
  height: 20rem;
  padding: $spacing-md;
}
.assetDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
  overflow-y: scroll;
}

.userCard {
  margin-top: $spacing-sm;
}

.saveIcon {
  @extend .plusIcon;
  color: $green;
}

.editIcon {
  @extend .plusIcon;
  color: $text-secondary;
}

.creator {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
}

.subheader {
  margin-bottom: $spacing-xs;
}
.searchBar {
  width: 100%;
  margin-bottom: $spacing-xs;
}
.noAssetDisplay {
  margin: 0 auto;
  padding: $spacing-lg;
}
.deleteButton {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.miles {
  display: flex;
  gap: $spacing-sm;
}

.worspaceQuickActionsContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.saveButton {
  min-width: 6rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .formRow {
    flex-wrap: wrap;
    gap: 0.1rem;
  }
  .leftHeader {
    width: 100%;
  }
  .header {
    flex-wrap: wrap-reverse;
  }
  .logoContainer {
    justify-content: center;
    margin-bottom: $spacing-md;
  }
  .assetDisplay {
    gap: 0.1rem;
  }
  .page {
    padding: $spacing-xs;
    padding-right: 0px;
    margin-top: $spacing-md;
  }
  .container {
    padding: $spacing-xs;
    padding-right: 0px;
  }
  .rootLogoContainer {
    margin: auto;
  }
  .templateDropdown {
    width: 80%;
  }
}
