@import '../../styles/variables.scss';

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  gap: $spacing-sm;
  align-items: center;
  margin: $spacing-sm 0;
}

.date {
  padding: $spacing-xs $spacing-sm;
  border: $border-light;
  border-radius: $border-radius-sm;
  width: 16rem;
  font-size: $font-size-md;
}
.noMargin {
  margin: 0;
  padding: 0;
}
.field {
  margin-bottom: $spacing-md;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.flex {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  flex-wrap: wrap;
}
.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.table {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.left {
  display: flex;
  justify-content: flex-start;
  margin-bottom: $spacing-sm;
}
.top {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  justify-content: space-between;
}
.input {
  display: flex;
  gap: $spacing-sm;
}
.dropdown {
  min-width: 7rem;
}

.container {
  background-color: white;
  width: 100%;
}

.questionSet {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.aviation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-sm;
  justify-content: space-between;
}
.tableRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.spacer {
  height: $spacing-sm;
}
.commentContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $spacing-md;
  width: 100%;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 100%;
  }
  .row {
    font-size: small;
    grid-template-columns: repeat(auto-fill, minmax(2.5rem, 1fr));
    gap: $spacing-xxs;
  }
  .text {
    font-size: smaller;
  }
  .aviation {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
